import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NavbarNew from '../Components/navbar';
import Sectiontitle1 from "../Photos/section-title-shape-1.png";
import Sectiontitle2 from "../Photos/section-title-shape-2.png";
import { backendURL } from '../constant';
import Header from '../Components/Header';

function AnnouncementDetail() {
    const { id } = useParams();
    console.log(id);
    const [data, setData] = useState(null);

    const callIndScreenImage = useCallback(async () => {
        try {
            const response = await axios.get(`${backendURL}/viewHomeAnnouncement/${id}`);
            const ScreenImageData = response.data;
            setData(ScreenImageData);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        callIndScreenImage();
    }, [callIndScreenImage]);
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Header />
            <div className='AdmissionSchoolbg'>
                <div className='container-fluid ps-md-5 px-0 text-light filter15 text-center'>
                    <p>Home / Announcement Detail</p><br></br>
                    <h1 className='fw-bold d-md-block d-none text-white'>Announcement Detail</h1>
                    <h1 className='fw-bold d-md-none d-block fs-1 text-white'>Announcement Detail</h1>

                </div>
            </div>

            <div className='container my-5 px-md-1 px-0'>
                <div className='d-flex align-items-center'>
                    <div className='b'></div>
                    <div className='d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded'>
                        <img height={"10%"} src={Sectiontitle1} alt="Section Title 1" />&nbsp;&nbsp;
                        <h3 className='text-light fw-bold mb-0 d-md-block d-none'>Announcement Detail</h3>&nbsp;&nbsp;
                        <h6 className='text-light fw-bold mb-0 d-md-none d-block'>Announcement Detail</h6>&nbsp;&nbsp;

                        <img height={"10%"} src={Sectiontitle2} alt="Section Title 2" />
                    </div>
                    <div className='b'></div>
                </div>
                {/* <h6 className='my-4'><span className='fw-bold'>Date:</span> <span className='text-primary'>{data?.date} - {currentYear}</span></h6>
                <h1 className='mt-3 text-center fw-bold'>{data?.title}</h1>
                <p className='mt-3 fs-6'><span className='fw-bold fs-4'>Description: </span>{data?.description}</p> */}



                <div className='d-flex flex-wrap shadow-lg border border-start-0 border-top-0 border-end-0 p-sm-4 p-2 text-justify mt-5'>
                    <div className=' col-md-3 col-12'>
                        <div className='event_date ' style={{width:"120px"}}>
                            <div className=' text-center d-flex event-date-wrap text-white'><p className='fw-bold '>{data?.date} {currentYear}</p></div>

                        </div>
                    </div>
                    <div className='col-md-9 col-12 mt-md-0 mt-3'>
                        <h2 className='fw-bold'>{data?.title}</h2>
                        <p className='mt-3 fs-6'><span className='fw-bold fs-4'>Description: </span>{data?.description}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AnnouncementDetail;

import React, { useEffect, useState } from "react";
import "../CSS/MeritStudents.css";
import "../CSS/MeritSlider.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "remixicon/fonts/remixicon.css";
import Footer from "../Components/Footer";
import Slider from "../Components/multiCarousel";
import Header from "../Components/Header";


function MeritStudents() {
  const [details, setDetails] = useState([]);
  //   const picUrl = `${backendURL}/public/uploads/`;
  const [currentPage, setCurrentPage] = useState(0);
  const universitiesPerPage = 12;

  const callProfile = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      fetch(`/getHighAchievers`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("getHighAchievers", result);

          const data = JSON.parse(result);
          console.log(data);
          setDetails(data);
        })
        .catch((error) => console.error(error));
      // const res = await fetch('/getHighAchievers', {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   credentials: 'include',
      // });

      // if (res.status === 200) {
      //   const data = await res.json();
      //   setDetails(data);
      // } else {
      //   const error = new Error(res.statusText);
      //   throw error;
      // }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    callProfile();
  }, []);

  const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
  const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
  const currentDetail = details.slice(
    indexOfFirstUniversity,
    indexOfLastUniversity
  );
  const achieversData = [
    {
      photos: "https://backend.skans.pk/public/uploads/photos_1711350641630.jpg",
      name: "Sarah Johnson",
      achievement: "Gold Medalist 2023"
    },
    {
      photos: "https://backend.skans.pk/public/uploads/photos_1711350641612.jpg", 
      name: "Michael Chen",
      achievement: "Best Student Award"
    },
    {
      photos: "https://backend.skans.pk/public/uploads/photos_1711350641649.jpg",
      name: "Emma Davis",
      achievement: "Academic Excellence"
    },
    {
      photos: "https://backend.skans.pk/public/uploads/photos_1711350641763.jpg",
      name: "James Wilson",
      achievement: "Outstanding Performance"
    },
    {
      photos: "https://backend.skans.pk/public/uploads/photos_1711350641649.jpg",
      name: "Sophia Garcia",
      achievement: "Merit Scholar"
    }
  ];
  return (
    <>
      <Header/>
      <div className="Meritlistbg mb-5">
        <div className="container-fluid ps-5 text-light filter12 text-center">
          <p></p>
          <br />
          <h1 className="text-white fw-bold d-sm-block d-none"></h1>
          <h1 className="text-white fw-bold fs-1 d-sm-none d-block">

          </h1>
        </div>
      </div>

      <div className="container">
        <div
          className="bg-dark-blue text-white fw-bold p-3 mt-5 mb-lg-0 mb-5 text-center rounded"
          data-aos="flip-right"
        >
          <h3 className="fw-bold text-white  d-sm-block d-none">
            Meet the Brightest Stars of SKANS School of Accountancy!
          </h3>
          <h5 className="fw-bold text-white  d-sm-none d-block">
            Meet the Brightest Stars of SKANS School of Accountancy!
          </h5>
        </div>
        <div className="d-flex flex-wrap align-items-center" data-aos="fade-up">
          <div
            className="col-lg-8 col-12 text-justify lh-lg"
            data-aos="fade-right"
          >
            Welcome to{" "}
            <span
              className="fontcolordark fw-bold"
              style={{ fontFamily: "-moz-initial" }}
            >
              SKANS
            </span>{" "}
            celebrating the exceptional achievements in the field of accountancy
            - the Merit List of Top Position Students. These individuals are not
            just students; they are our pride, our stars, who have illuminated
            the realm of accountancy with their unparalleled dedication and
            brilliance. Securing the top positions, they have showcased their
            unwavering commitment to academic excellence and a profound
            understanding of the intricate world of numbers. Their remarkable
            accomplishments stand as a testament to their hard work,
            perseverance, and intellectual prowess. Join us in acknowledging
            these outstanding individuals who have risen above the rest,
            inspiring us all and setting new standards of achievement in the
            realm of accountancy.
          </div>
          <div className="col-lg-4 col-10" data-aos="fade-left">
            <img
              className="img-fluid"
              src= {require("../Photos/MeritListVector.png")}
              alt="Merit List"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
  <h1 className="bg-dark-blue text-white p-3 text-center mx-5 col-3 rounded">COLEGES</h1>
  </div>
      <div className="d-flex mb-5">
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2023 Batch
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <Slider achieversData={achieversData} />
        </div>
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2024 Batch
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <Slider achieversData={achieversData} ></Slider>
        </div>
      </div>


<div className="d-flex justify-content-center">
  <h1 className="bg-dark-blue text-white p-3 text-center mx-5 col-3 rounded">SCHOOLS</h1>
  </div>

      <div className="d-flex mb-5">
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2023 Year
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <Slider achieversData={achieversData} />
        </div>
        <div className="col-6">
          <div
            data-aos="zoom-in"
            className="my-5 d-flex align-items-center justify-content-center border p-3 bg-dark-blue rounded px-5"
          >
            <h3 className="text-light fw-bold mb-0 text-uppercase text-center">
              2024 Year
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <Slider achieversData={achieversData} ></Slider>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default MeritStudents;

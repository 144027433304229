import React from 'react'
import { Link } from 'react-router-dom';

import Logo from "../Photos/Skanslogo1.png"
import NavbarNew from './navbar';
// import NavbarNew from './navbar';

function Header() {
  const toggleMobileMenu = () => {
    document.querySelector('body').classList.toggle('mobile-nav-active');
    document.querySelector('.mobile-nav-toggle').classList.toggle('bi-list');
    document.querySelector('.mobile-nav-toggle').classList.toggle('bi-x');
  };
    return (
        <header id="header" className="header d-flex align-items-center fixed-top d-flex flex-column">
          <NavbarNew/> 
          <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between bg-dark-blue p-2 rounded-bottom">
            <a href="/" className="logo d-flex align-items-center">
              {/* Uncomment the line below if you also wish to use an image logo */}
              {/* <img src="assets/img/logo.png" alt="Logo" /> */}
              <img
                width={"200px"}
                className="img-fluid"
                src={Logo}
                alt=""
              ></img>
            </a>
            <nav id="navmenu" className="navmenu">
              <ul>
                <li>
                  <Link to="/" className="active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/SkansGallery">Life At SKANS</Link>
                </li>
                <li>
                  <Link to="/Meritstudents" className="text-danger">
                    High Achievers
                  </Link>
                </li>
                <li>
                  <Link to="/CAProgram" className="">
                    CA
                  </Link>
                </li>
                <li>
                  <Link to="/ACCAProgram" className="">
                    ACCA
                  </Link>
                </li>
                <li>
                  <Link to="/ContactUs">Contact</Link>
                </li>
                {/* <li>
                  <a
                    style={{ fontSize: "14px" }}
                    href="https://skans.pk/onlinepayments/challans.php"
                  >
                    Online Challan Payment
                  </a>
                </li> */}
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={toggleMobileMenu}></i>

            </nav>
          </div>
        </header>
      );
  };

export default Header

